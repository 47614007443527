import $ from 'jquery';
//import jQuery from 'jquery';
//import 'bootstrap/js/dist/util';
//import 'bootstrap/js/dist/dropdown';
import { Tooltip, Toast, Popover } from 'bootstrap';

import './scss/app.scss';

window.$ = $;

$(document).ready(function(){
	console.log('document ready');
	
	// Navigation levels
	$('.navbar .dropdown').hover(function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideDown(1);
	}, function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideUp(1);
	});

	$('.navbar .dropdown > a').click(function(){
		location.href = this.href;
	});



	function owlHomeCarouselSlider(){
		jQuery('.home-carousel-wrap').owlCarousel({
		    //center: true,
		    items: 3,
		    margin:30,
		    nav:true,
		    navText: ['<div class="arrow-wrap"><span class="arrow left"></span></div>','<div class="arrow-wrap"><span class="arrow right"></span></div>'],
		    dots: false,
		    responsive:{
		        0:{
		            items:1
		        },
		        600:{
		            items:2
		        },
		        1000:{
		            items:3
		        }
		    }
		});
	}


	// Full page search
	const searchIco = document.querySelector(".search-icon");
	const closeBtn = document.querySelector(".close");

	searchIco.addEventListener("click", function(){
		const body = document.body;
		body.classList.toggle("search-active");
		console.log('search active');
	});

	closeBtn.addEventListener("click", function(){
		const body = document.body;
		body.classList.toggle("search-active");		
	});


	// Language switch placeholder
	function languageSwitcher(){
		$('.language-switch-btn').on('click', function(e){
			e.preventDefault();
			console.log('lang switch');
		});
	}

	// Readmore thema's
	function readMoreTheme(){
		jQuery('.theme-description__inner').readmore({
			moreLink: '<p class="block-50"><a href="#" class="btn-wrap"><span class="btn btn--purple-transparent">Lees meer</span><span class="arrow-wrap"><span class="posr"><span class="arrow right"></span></span></span></a></p>',
			lessLink: ''
		});
		console.log('readmore');
	}


	// Fire ze functions
	owlHomeCarouselSlider();
	languageSwitcher();
	readMoreTheme();

});